import React from 'react';
import SocialLogin from '../components/react/social_login/social_login';

interface Props {
  authToken: string;
  returnTo?: string;
}

const LoginSocialMediaSection: React.FC<Props> = ({ authToken, returnTo }) => {
  return (
    <div data-testid='social-logins'>
      <SocialLogin
        provider={'google_oauth2'}
        authToken={authToken}
        buttonText='Sign in with Google'
        iconClass='icon-google'
        redirectTo={returnTo}
      />
      <SocialLogin
        provider={'linkedin'}
        authToken={authToken}
        buttonText='Sign in with LinkedIn'
        iconClass='icon-linkedin'
        redirectTo={returnTo}
      />
      <SocialLogin
        provider={'facebook'}
        authToken={authToken}
        buttonText='Sign in with Facebook'
        iconClass='icon-facebook-square'
        redirectTo={returnTo}
      />
    </div>
  );
};

export default LoginSocialMediaSection;
